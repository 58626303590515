
import { Component, Vue, Prop } from 'vue-property-decorator'
import { DialPlan, DialPlanMode } from '@/models/dialplan/dialplan'

@Component
export default class BaseStep extends Vue {
  @Prop({ required: true }) dialPlan!: DialPlan
  @Prop({ default: false }) readOnly!: boolean

  protected completedValue: string | null = null

  get isBasic () {
    return this.$store.state.newDialPlanMode === DialPlanMode.Basic
  }

  get isAdvanced () {
    return this.$store.state.newDialPlanMode === DialPlanMode.Advanced
  }
}
