


































import { Component, Prop } from 'vue-property-decorator'
import BaseStep from '@/components/wizard/BaseStep.vue'
import { DialPlan } from '@/models/dialplan/dialplan'

@Component
export default class BasicStep extends BaseStep {
  completedValue: string | null = null

  @Prop()
  dialPlan!: DialPlan

  get nameValue () {
    return this.dialPlan.getName()
  }

  set nameValue (value: string) {
    this.dialPlan.setName(value)
  }

  get messageValue () {
    return this.dialPlan.getWelcomePlaybackFilename()
  }

  set messageValue (value: string) {
    this.dialPlan.setWelcomePlaybackFilename(value)
  }

  get isNameValid () {
    if (this.nameValue === '') {
      return null // Keep initial valid state
    }

    const alphaNumericRegex = new RegExp(/^[0-9a-zA-Z]+$/)

    return alphaNumericRegex.test(this.nameValue)
  }

  get isMessageValid () {
    if (this.messageValue === '') {
      return null
    }

    return this.messageValue.length >= 4
  }

  get isValid () {
    return this.isNameValid && this.isMessageValid
  }
}
