import Model, { apiToModelTransformer, modelToApiTransformer } from '@/models/base'
import { JsonData } from '@/helpers/api'

export enum TimeRouteDay {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday
}

export class TimeConfigurationSchedule extends Model {
  protected fieldDefinition = {
    day: 'Day',
    startTime: 'startTime',
    endTime: 'endTime'
  }

  private day!: TimeRouteDay
  private startTime = ''
  private endTime = ''

  getDay (): TimeRouteDay {
    return this.day
  }

  setDay (day: TimeRouteDay) {
    this.day = day

    return this
  }

  getStartTime (): string {
    return this.startTime
  }

  setStartTime (startTime: string) {
    this.startTime = startTime

    return this
  }

  getEndTime (): string {
    return this.endTime
  }

  setEndTime (endTime: string) {
    this.endTime = endTime

    return this
  }

  fromApiTransformer (data: JsonData): TimeConfigurationSchedule {
    apiToModelTransformer(this, data, this.fieldDefinition)

    return this
  }

  toApiTransformer (): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition)
  }
}
