import Vue from 'vue'
import Vuex from 'vuex'
import { AuthUtil } from '@/utils/auth'
import { DialPlan, DialPlanMode } from '@/models/dialplan/dialplan'
import Installation from '@/models/installation'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: AuthUtil.isAuthenticated,
    dialPlan: DialPlan,
    selectedInstallation: Installation,
    newDialPlanMode: DialPlanMode
  },
  mutations: {
    setAuthenticated (state, authenticated: boolean) {
      state.isAuthenticated = authenticated
    },
    setDialPlan (state, dialPlan: typeof DialPlan) {
      state.dialPlan = dialPlan
    },
    setInstallation (state, installation: typeof Installation) {
      state.selectedInstallation = installation
    },
    setNewDialPlanMode (state, mode: typeof DialPlanMode) {
      state.newDialPlanMode = mode
    }
  },
  actions: {
  },
  modules: {
  }
})
