

















































































import { DialPlan } from '@/models/dialplan/dialplan'
import router from '@/router'
import { Component, Vue } from 'vue-property-decorator'
import { Api } from '@/helpers/api'
import EmptyState from '@/components/data/EmptyState.vue'
import InstallationPicker from '@/components/InstallationPicker.vue'
import { AuthUtil } from '@/utils/auth'
import Repository from '@/repos/base'
import Model from '@/models/base'
import Summary from '@/components/Summary.vue'

@Component({
  components: {
    EmptyState,
    InstallationPicker,
    Summary
  }
})
export default class DialPlansView extends Vue {
  fields = [
    {
      key: 'name',
      sortable: true
    },
    {
      key: 'id',
      label: 'ID',
      sortable: false,
      thClass: 'w-50'
    },
    {
      key: 'mode',
      label: 'Type',
      sortable: true
    },
    { key: 'actions', label: '' }
  ]

  sortBy = 'name'
  filter = ''

  // Assuming initial loading state
  isLoading = true

  dialPlans!: DialPlan[] | Model[]

  private repo = new Repository(Api.DialPlanService, 'dialplans', DialPlan, 'data')

  data () {
    return {
      dialPlans: []
    }
  }

  created () {
    if (AuthUtil.installationId) {
      this.getDialPlans()
    }
  }

  get installationId () {
    return AuthUtil.installationId
  }

  rowClickHandler (row: { item: DialPlan }) {
    this.$store.commit('setDialPlan', row.item)
    router.push({ name: 'Edit', params: { dialPlanId: row.item.getId() } })
  }

  async getDialPlans () {
    this.isLoading = true
    this.dialPlans = await this.repo
      .withParams({ installationId: AuthUtil.installationId })
      .fetch()

    this.isLoading = false
  }

  get hasDialplans () {
    return this.dialPlans && this.dialPlans.length >= 1
  }

  editDialPlan (row: { item: DialPlan }) {
    this.$store.commit('setDialPlan', new DialPlan().fromApiTransformer(row.item))
    this.$store.commit('setNewDialPlanMode', row.item.mode)

    router.push({ name: 'Edit', params: { dialPlanId: row.item.getId() } })
  }
}
