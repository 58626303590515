var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.steps, function(step, index) {
      return _c(
        "Step",
        {
          key: index,
          ref: step.name,
          refInFor: true,
          attrs: {
            name: step.name,
            accordion: _vm.name,
            number: index + 1,
            title: step.title,
            description: step.description,
            pill: step.pill,
            "disabled-function": step.disabled,
            "is-active": _vm.activeStep === step.name,
            "is-final-step": index === _vm.steps.length - 1,
            validator: step.validator
          },
          on: {
            next: _vm.onNext,
            previous: _vm.onPrevious,
            select: _vm.onSelect
          }
        },
        [
          _c(
            "template",
            { slot: "completedValue" },
            [_vm._t(step.name + "-complete")],
            2
          ),
          _c("template", { slot: "default" }, [_vm._t(step.name)], 2)
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }