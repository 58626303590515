
























import { Component, Vue } from 'vue-property-decorator'
import HubLogo from '@/components/layout/HubLogo.vue'
import InstallationPicker from '@/components/InstallationPicker.vue'
import { AuthUtil } from '@/utils/auth'

@Component({
  components: {
    HubLogo,
    InstallationPicker
  }
})
export default class App extends Vue {
  async logout () {
    try {
      await AuthUtil.logout()
      this.$store.commit('setAuthenticated', AuthUtil.isAuthenticated)
      await this.$router.push('/login')
    } catch (e) {

    }
  }

  get installationId () {
    return AuthUtil.installationId
  }
}
