














































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class Step extends Vue {
  @Prop({ required: true, default: 0, type: Number }) number!: number
  @Prop({ required: true }) name!: string
  @Prop({ required: true }) title!: string
  @Prop(String) description!: string
  @Prop(String) pill!: string
  @Prop({ required: true }) accordion!: string
  @Prop({ type: Function }) validator!: () => boolean
  @Prop({ type: Boolean, default: false }) isFinalStep!: boolean
  @Prop({ type: Function }) disabledFunction!: () => boolean
  @Prop({ type: Boolean, default: false }) isActive!: boolean

  private complete = false

  created () {
    if (this.isValid && !this.disabled) {
      this.complete = true
    }
  }

  get disabled (): boolean {
    if (typeof this.disabledFunction === 'function') {
      return this.disabledFunction()
    }

    return false
  }

  get isValid (): boolean {
    if (typeof this.validator === 'function') {
      return this.validator()
    }

    return true
  }

  get collapseItemId (): string {
    return `step-${this.number}`
  }

  get isFirstStep (): boolean {
    return this.number === 1
  }

  get isComplete () {
    return this.complete
  }

  @Emit('select')
  doSelectStep () {
    if (!this.isActive && !this.disabled && this.complete) {
      return this.name
    }

    return undefined
  }

  @Emit('next')
  doNextStep () {
    return this
  }

  @Emit('previous')
  doPrevious () {
    return this
  }

  @Emit('complete')
  doComplete () {
    this.complete = true

    this.doNextStep()
  }
}
