var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      staticClass: "m-2",
      attrs: {
        id: "installationPicker",
        block: "",
        variant: "outline",
        "toggle-class": "text-" + _vm.align,
        "menu-class": "w-100"
      },
      on: { show: _vm.getInstallations },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function() {
            return [
              _vm.isLoadingSelected
                ? _c("b-spinner", { attrs: { label: "Loading...", small: "" } })
                : [_vm._v(_vm._s(_vm.dropDownName))]
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-dropdown-form",
        [
          _c("b-form-input", {
            attrs: {
              placeholder: "Search by name",
              type: "text",
              debounce: "500",
              trim: "",
              autofocus: ""
            },
            on: { update: _vm.doSearch }
          })
        ],
        1
      ),
      _c("b-dd-divider"),
      _vm.isLoading
        ? _c(
            "b-dropdown-text",
            { staticClass: "text-center" },
            [_c("b-spinner", { attrs: { label: "Loading..." } })],
            1
          )
        : _vm.installations
        ? _vm._l(_vm.installations, function(installation) {
            return _c(
              "b-dropdown-item",
              {
                key: installation.getId(),
                on: {
                  click: function($event) {
                    return _vm.doSelectInstallation(installation)
                  }
                }
              },
              [_vm._v(" " + _vm._s(installation.getName()) + " ")]
            )
          })
        : _c("b-dropdown-text", { staticClass: "text-center" }, [
            _vm._v(" No installations found... ")
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }