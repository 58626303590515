import { Destination } from './destination'
import { Ivr } from './ivr'
import { TimeConfiguration } from '@/models/dialplan/time-configuration'
import Model, { apiToModelTransformer, modelToApiTransformer } from '@/models/base'
import { JsonData } from '@/helpers/api'
import { AuthUtil } from '@/utils/auth'

export enum DialPlanMode { Basic = 'Basic', Advanced = 'Advanced', Blank = 'Blank'}

export class DialPlan extends Model {
  protected fieldDefinition = {
    id: 'id',
    name: 'name',
    welcomePlaybackFilename: 'welcomePlaybackFilename'
  }

  private id = ''
  private name = ''
  private welcomePlaybackFilename = ''
  private ivr: Ivr = new Ivr()
  private destinations: { [index: string]: Destination } = {}
  private timeConfiguration: TimeConfiguration = new TimeConfiguration()

  getId (): string {
    return this.id
  }

  setId (id: string) {
    this.id = id
    return this
  }

  getName (): string {
    return this.name
  }

  setName (name: string) {
    this.name = name

    return this
  }

  get mode (): DialPlanMode {
    // if a dial plan has over 5 options, or has time of day routing, then it's "advanced"
    if ((this.ivr.getOptions().length > 5) || (this.timeConfiguration && this.timeConfiguration.getRecurringOpeningSchedule().length)) {
      return DialPlanMode.Advanced
    }

    return DialPlanMode.Basic
  }

  getWelcomePlaybackFilename (): string {
    return this.welcomePlaybackFilename
  }

  setWelcomePlaybackFilename (welcomePlaybackFilename: string) {
    this.welcomePlaybackFilename = welcomePlaybackFilename

    return this
  }

  getIvr (): Ivr {
    return this.ivr
  }

  setIvr (ivr: Ivr) {
    this.ivr = ivr

    return this
  }

  getDestinations (): { [index: string]: Destination } {
    return this.destinations
  }

  setDestinations (destinations: { [index: string]: Destination }) {
    this.destinations = destinations

    return this
  }

  addDestination (index: string, destination: Destination) {
    this.destinations[index] = destination

    return this
  }

  removeDestination (index: string) {
    delete this.destinations[index]

    return this
  }

  setTimeConfiguration (timeRouteConfig: TimeConfiguration) {
    this.timeConfiguration = timeRouteConfig

    return this
  }

  getTimeConfiguration (): TimeConfiguration {
    return this.timeConfiguration
  }

  fromApiTransformer (data: JsonData): DialPlan {
    apiToModelTransformer(this, data, this.fieldDefinition)

    if (data.destinations && typeof data.destinations === 'object' && !Array.isArray(data.destinations)) {
      for (const index in data.destinations) {
        const destination = data.destinations[index]
        if (destination && typeof destination === 'object' && !Array.isArray(destination)) {
          const destinationModel = new Destination()
            .fromApiTransformer(destination)

          this.addDestination(destinationModel.getDialPlanRef(), destinationModel)
        }
      }

      if (data.timeConfiguration && typeof data.timeConfiguration === 'object' && !Array.isArray(data.timeConfiguration)) {
        const timeConfiguration = new TimeConfiguration()
          .fromApiTransformer(data.timeConfiguration)

        const destinationObject = data.destinations[timeConfiguration.getClosedDestination()]
        if (destinationObject && typeof destinationObject === 'object' && !Array.isArray(destinationObject) && typeof destinationObject.dialPlanRef === 'string') {
          const destinationModel = this.destinations[destinationObject.dialPlanRef]

          if (destinationModel) {
            timeConfiguration.setClosedDestination(destinationModel.getDialPlanRef())
          }
        }

        this.setTimeConfiguration(timeConfiguration)
      }

      if (data.ivr && typeof data.ivr === 'object' && !Array.isArray(data.ivr)) {
        const ivrModel = new Ivr()
          .fromApiTransformer(data.ivr)

        for (const option of ivrModel.getOptions()) {
          const destinationObject = data.destinations[option.getDestination()]
          if (destinationObject && typeof destinationObject === 'object' && !Array.isArray(destinationObject) && typeof destinationObject.dialPlanRef === 'string') {
            const destinationModel = this.destinations[destinationObject.dialPlanRef]

            if (destinationModel) {
              option.setDestination(destinationModel.getDialPlanRef())
            }
          }
        }

        this.setIvr(ivrModel)
      }
    }

    return this
  }

  toApiTransformer (): JsonData {
    const data = modelToApiTransformer(this, this.fieldDefinition)
    data.destinations = {}

    for (const [index, destination] of Object.entries(this.destinations)) {
      data.destinations[`${index}`] = destination.toApiTransformer()
    }

    data.ivr = this.ivr.toApiTransformer()

    if (this.timeConfiguration) {
      data.timeConfiguration = this.transformClosedDestination(this.timeConfiguration.toApiTransformer())
    }

    delete data.id

    if (AuthUtil.installationId) {
      data.installationId = parseInt(AuthUtil.installationId)
    }

    return data
  }

  private transformClosedDestination (timeConfiguration: JsonData): JsonData {
    Object.entries(this.destinations).forEach(destination => {
      const [dialPlanRef] = destination
      if (dialPlanRef === timeConfiguration.closedDestination) {
        timeConfiguration.closedDestination = `${dialPlanRef}`
      }
    })

    return timeConfiguration
  }
}
