






























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import Step from '@/components/steps/Step.vue'

export type StepConfig = {
  name: string;
  title: string;
  description: string;
  pill?: string;
  disabled?: () => boolean;
  validator: () => boolean;
  onComplete?: Function;
}

@Component({
  components: {
    Step
  }
})
export default class Steps extends Vue {
  @Prop({ required: true, type: Array }) steps!: StepConfig[]
  @Prop({ required: true, type: String }) name!: string

  private activeStep = ''

  created () {
    this.activeStep = this.steps[0].name
  }

  onNext (step: Step) {
    if ((step.isComplete || step.disabled) && !step.isFinalStep) {
      const stepConfig = this.steps.find(
        (config) => {
          return config.name === step.name
        }
      )

      if (stepConfig) {
        const nextStepIndex = this.steps.indexOf(stepConfig) + 1

        if (this.steps[nextStepIndex]) {
          const stepComponent = this.getStepByName(this.steps[nextStepIndex].name)

          if (stepComponent) {
            if (stepComponent.disabled) {
              this.onNext(stepComponent)
              return
            }

            this.activeStep = stepComponent.name
          }
        }
      }
    }

    if (step.isFinalStep) {
      this.doComplete()
    }
  }

  onPrevious (step: Step) {
    const stepConfig = this.steps.find(
      (config) => {
        return config.name === step.name
      }
    )

    if (stepConfig) {
      const prevStepIndex = this.steps.indexOf(stepConfig) - 1

      if (this.steps[prevStepIndex]) {
        const stepComponent = this.getStepByName(this.steps[prevStepIndex].name)

        if (stepComponent) {
          if (stepComponent.disabled) {
            this.onPrevious(stepComponent)
            return
          }

          this.activeStep = stepComponent.name
        }
      }
    }
  }

  onSelect (name: string | MouseEvent) {
    if (typeof name === 'string') {
      this.activeStep = name
    }
  }

  getStepByName (name: string): Step | undefined {
    const step = this.$children.find(
      (child) => {
        if (child instanceof Step && child.name === name) {
          return child
        }
      }
    )

    if (step instanceof Step) {
      return step
    }
  }

  @Emit('complete')
  doComplete () {
    return this
  }
}
