














































































import { Component, Emit } from 'vue-property-decorator'
import BaseStep from '@/components/wizard/BaseStep.vue'
import Step from '@/components/steps/Step.vue'
import DestinationPicker from '@/components/wizard/DestinationPicker.vue'
import RecurringSchedule from '@/components/wizard/RecurringSchedule.vue'
import { TimeConfiguration } from '@/models/dialplan/time-configuration'
import { Destination } from '@/models/dialplan/destination'
import { TimeConfigurationSchedule, TimeRouteDay } from '@/models/dialplan/time-configuration-schedule'

@Component({
  components: {
    Step,
    DestinationPicker,
    RecurringSchedule
  }
})
export default class TimeRoutingStep extends BaseStep {
  private quickFillStartDay: TimeRouteDay | null = null
  private quickFillEndDay: TimeRouteDay | null = null
  private quickFillStartTime = ''
  private quickFillEndTime = ''

  private commonTimeZones = [
    'Europe/London',
    'Europe/Brussels',
    'Europe/Madrid',
    'America/New_York',
    'America/Los_Angeles'
  ]

  get timeConfiguration () {
    return this.dialPlan.getTimeConfiguration()
  }

  get destinations () {
    return this.dialPlan.getDestinations()
  }

  get isValid () {
    return (
      this.isTimeZoneValid &&
      this.timeConfiguration.getClosedDestination() !== '' &&
      this.timeConfiguration.getRecurringOpeningSchedule().length > 0
    )
  }

  get timeZone (): string {
    return this.timeConfiguration.getTimeZone()
  }

  set timeZone (value: string) {
    if (value) {
      this.timeConfiguration.setTimeZone(value)
    }
  }

  get isTimeZoneValid () {
    if (this.timeZone === '') {
      return null
    }

    try {
      Intl.DateTimeFormat(undefined, { timeZone: this.timeZone })

      return true
    } catch (ex) {
      return false
    }
  }

  get closedDestination (): string {
    return this.timeConfiguration.getClosedDestination()
  }

  set closedDestination (value: string) {
    if (value) {
      this.timeConfiguration.setClosedDestination(value)
    }
  }

  doSetClosedDestination (value: Destination) {
    this.closedDestination = value.getDialPlanRef()
  }

  get daySelectOptions (): { value: null | TimeRouteDay; text: string }[] {
    return [
      { value: null, text: 'Select a day' },
      { value: TimeRouteDay.Monday, text: TimeRouteDay[TimeRouteDay.Monday] },
      { value: TimeRouteDay.Tuesday, text: TimeRouteDay[TimeRouteDay.Tuesday] },
      { value: TimeRouteDay.Wednesday, text: TimeRouteDay[TimeRouteDay.Wednesday] },
      { value: TimeRouteDay.Thursday, text: TimeRouteDay[TimeRouteDay.Thursday] },
      { value: TimeRouteDay.Friday, text: TimeRouteDay[TimeRouteDay.Friday] },
      { value: TimeRouteDay.Saturday, text: TimeRouteDay[TimeRouteDay.Saturday] },
      { value: TimeRouteDay.Sunday, text: TimeRouteDay[TimeRouteDay.Sunday] }
    ]
  }

  get isQuickFillValid () {
    return (this.quickFillStartDay !== null) &&
      (this.quickFillEndDay !== null) &&
      (this.quickFillStartTime !== '') &&
      (this.quickFillEndTime !== '') &&
      (this.quickFillStartTime < this.quickFillEndTime)
  }

  doQuickFill () {
    if (this.quickFillStartDay && this.quickFillEndDay) {
      for (const day of TimeConfiguration.days) {
        if ((day >= this.quickFillStartDay) && (day <= this.quickFillEndDay)) {
          const existing = this.timeConfiguration.getScheduleForDay(day)

          if (existing) {
            existing
              .setDay(day)
              .setStartTime(this.quickFillStartTime)
              .setEndTime(this.quickFillEndTime)

            continue
          }

          this.timeConfiguration.addRecurringOpeningSchedule(
            new TimeConfigurationSchedule()
              .setDay(day)
              .setStartTime(this.quickFillStartTime)
              .setEndTime(this.quickFillEndTime)
          )
        }
      }
    }

    this.doCancelQuickFill()
  }

  doCancelQuickFill () {
    this.$bvModal.hide('quick-fill')
    this.quickFillStartDay = null
    this.quickFillEndDay = null
    this.quickFillStartTime = ''
    this.quickFillEndTime = ''
  }

  onUpdateSchedule (recurringSchedule: TimeConfigurationSchedule[]) {
    this.timeConfiguration.setRecurringOpeningSchedule(recurringSchedule)
  }

  @Emit('addDestination')
  doAddDestination (destination: Destination) {
    return destination
  }
}
