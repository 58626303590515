















import { Component, Prop, Vue } from 'vue-property-decorator'
import TimeSchedule from '@/components/wizard/TimeSchedule.vue'
import { DialPlan } from '@/models/dialplan/dialplan'
import { TimeConfigurationSchedule, TimeRouteDay } from '@/models/dialplan/time-configuration-schedule'
import { TimeConfiguration } from '@/models/dialplan/time-configuration'

@Component({
  components: {
    TimeSchedule
  }
})
export default class RecurringSchedule extends Vue {
  @Prop({ required: true }) dialPlan!: DialPlan

  get timeConfiguration () {
    return this.dialPlan.getTimeConfiguration()
  }

  private days = TimeConfiguration.days

  get recurringSchedule () {
    return this.timeConfiguration.getRecurringOpeningSchedule()
  }

  doUpdateSchedule (schedule: TimeConfigurationSchedule) {
    const daySchedule = this.timeConfiguration.getScheduleForDay(schedule.getDay())
    if (daySchedule) {
      daySchedule
        .setStartTime(schedule.getStartTime())
        .setEndTime(schedule.getEndTime())

      return
    }

    this.timeConfiguration.addRecurringOpeningSchedule(schedule)
  }

  doRemoveSchedule (day: TimeRouteDay) {
    this.timeConfiguration.removeRecurringOpeningScheduleForDay(day)
  }
}
