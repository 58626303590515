































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import Repository from '@/repos/base'
import { Api } from '@/helpers/api'
import DestinationMap from '@/models/destination-map'
import { AuthUtil } from '@/utils/auth'
import { Filter, FilterCollection, FilterOperand } from '@/helpers/filter'
import Model from '@/models/base'
import MapItem from '@/models/map-item'

@Component
export default class MapItemPicker extends Vue {
  @Prop({ required: true }) selectedMapItemKeys!: string[]
  private destinationMapRepo = new Repository(
    Api.Hub,
    `config/igrps/${AuthUtil.installationId}/dialplans/destinationMaps`,
    DestinationMap,
    'destinationMaps'
  )

  private destinationMaps: Array<Model|DestinationMap> | null = null
  private selectedDestinationMap: DestinationMap | Model | null = null

  private newMapItem: MapItem | null = null

  private isLoadingMaps = true
  private isLoadingItems = false
  private isSaving = false
  private newMapItemError = null
  private filterValue = ''

  async created () {
    await this.doFetchDestinationMaps()
  }

  get isMapItemKeyValid () {
    if (this.newMapItemKey === null || this.newMapItemKey === '') {
      return null
    }

    return this.newMapItemKey.length > 0
  }

  get isMapItemValueValid () {
    if (this.newMapItemValue === null || this.newMapItemValue === '') {
      return null
    }

    return this.newMapItemValue.length > 0
  }

  get isNewMapItemValid () {
    return this.isMapItemKeyValid && this.isMapItemValueValid
  }

  get newMapItemKey () {
    if (this.newMapItem) {
      return this.newMapItem.getKey()
    }

    return null
  }

  set newMapItemKey (value: string | null) {
    if (this.newMapItem && value) {
      this.newMapItem.setKey(value)
    }
  }

  get newMapItemValue () {
    if (this.newMapItem) {
      return this.newMapItem.getValue()
    }

    return null
  }

  set newMapItemValue (value: string | null) {
    if (this.newMapItem && value) {
      this.newMapItem.setValue(value)
    }
  }

  async doUpdateFilter (value: string) {
    this.filterValue = value

    if (this.selectedDestinationMap) {
      return this.doFetchMapItems()
    }

    return this.doFetchDestinationMaps()
  }

  async doFetchDestinationMaps () {
    this.isLoadingMaps = true

    let filters = null

    if (this.filterValue) {
      filters = new FilterCollection()
        .addFilter(new Filter('mapName', FilterOperand.IncludesI, this.filterValue))
    }

    this.destinationMaps = await this.destinationMapRepo.fetch(filters)

    if (this.destinationMaps.length === 1) {
      await this.doSelectDestinationMap(this.destinationMaps[0])
    }

    this.isLoadingMaps = false
  }

  async doSelectDestinationMap (destinationMap: DestinationMap | Model) {
    this.filterValue = ''
    this.selectedDestinationMap = destinationMap
    await this.doFetchMapItems()
  }

  async doFetchMapItems () {
    if (this.selectedDestinationMap) {
      const repo = new Repository(
        Api.Hub,
        `config/igrps/${AuthUtil.installationId}/dialplans/destinationMaps/${this.selectedDestinationMap.getId()}/items`,
        MapItem,
        'mapItems'
      )

      this.isLoadingItems = true
      const mapItems = await repo.fetch(
        new FilterCollection(
          new Filter('itemKey', FilterOperand.IncludesI, this.filterValue),
          new Filter('itemKey', FilterOperand.NotInI, this.selectedMapItemKeys)
        )
      )
      this.selectedDestinationMap.setMapItems(mapItems)
      this.isLoadingItems = false
    }
  }

  doCreateMapItem () {
    this.newMapItem = new MapItem()
  }

  doCancelCreateMapItem () {
    this.newMapItem = null
  }

  async doSaveMapItem () {
    if (!this.selectedDestinationMap || !this.newMapItem) {
      return null
    }

    this.isSaving = true

    const repo = new Repository(
      Api.Hub,
      `config/igrps/${AuthUtil.installationId}/dialplans/destinationMaps/${this.selectedDestinationMap.getId()}/items`,
      MapItem,
      'destinationMap'
    )

    try {
      await repo.create(this.newMapItem)
      this.selectedDestinationMap.addMapItem(this.newMapItem)
      this.doSelectMapItem(this.newMapItem)
      this.newMapItem = null
      this.newMapItemError = null
    } catch (error) {
      if (error.response && error.response.data) {
        this.newMapItemError = error.response.data.message
      }
    }

    this.isSaving = false
  }

  @Emit('select')
  doSelectMapItem (mapItem: MapItem) {
    return mapItem
  }
}
