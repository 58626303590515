var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ivr
    ? _c(
        "div",
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "playbackFilename",
                description:
                  "Enter the filename of the message that will be played to callers when presented with the menu.",
                "invalid-feedback":
                  "Playback filename should contain at least 4 characters.",
                label: "Playback Message",
                "label-for": "playbackFilename"
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "playbackFilename",
                  state: _vm.isPlaybackFilenameValid,
                  trim: ""
                },
                model: {
                  value: _vm.playbackFilenameValue,
                  callback: function($$v) {
                    _vm.playbackFilenameValue = $$v
                  },
                  expression: "playbackFilenameValue"
                }
              })
            ],
            1
          ),
          _c("label", [_vm._v("IVR options")]),
          _vm._m(0),
          _c(
            "b-row",
            { staticClass: "my-3", attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.ivrOptions.length) + " of "),
                  _c("strong", [_vm._v(_vm._s(_vm.allowedOptions))]),
                  _vm._v(" allowed options. ")
                ])
              ]),
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: {
                        disabled: _vm.ivrOptions.length >= _vm.allowedOptions,
                        variant: "primary"
                      },
                      on: { click: _vm.doCreateOption }
                    },
                    [_c("b-icon-plus"), _vm._v(" Add Option")],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.ivrOptions, function(ivrOption) {
            return _c(
              "b-row",
              {
                key: ivrOption.getDigit(),
                staticClass: "my-3",
                attrs: { "align-v": "center" }
              },
              [
                _c(
                  "b-col",
                  [
                    _c("b-badge", { attrs: { pill: "", variant: "primary" } }, [
                      _vm._v(_vm._s(ivrOption.getDigit()))
                    ]),
                    _vm._v(" to destination "),
                    _c("strong", [_vm._v(_vm._s(ivrOption.getDestination()))])
                  ],
                  1
                ),
                _c(
                  "b-col",
                  [
                    _c(
                      "b-button-group",
                      { staticClass: "float-right" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-secondary" },
                            on: {
                              click: function($event) {
                                return _vm.doEditOption(ivrOption)
                              }
                            }
                          },
                          [_c("b-icon-pencil")],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-danger" },
                            on: {
                              click: function($event) {
                                return _vm.doRemoveOption(ivrOption)
                              }
                            }
                          },
                          [_c("b-icon-x")],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "b-modal",
            {
              attrs: {
                id: "add-option",
                title: _vm.ivr.hasOption(_vm.selectedOption)
                  ? "Update IVR option"
                  : "Add IVR option",
                centered: "",
                "no-close-on-esc": "",
                "no-close-on-backdrop": "",
                "hide-header-close": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-footer",
                    fn: function() {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "link" },
                            on: { click: _vm.doCancelOption }
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _vm.$refs.optionModal
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: !_vm.$refs.optionModal.isValid,
                                  variant: "success"
                                },
                                on: { click: _vm.$refs.optionModal.doSave }
                              },
                              [
                                _vm.ivr.hasOption(_vm.selectedOption)
                                  ? [_vm._v("Update")]
                                  : _vm._e(),
                                !_vm.ivr.hasOption(_vm.selectedOption)
                                  ? [_vm._v("Add")]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2891100132
              )
            },
            [
              _c("Option", {
                ref: "optionModal",
                attrs: {
                  destinations: _vm.dialPlan.getDestinations(),
                  ivr: _vm.ivr,
                  "ivr-option": _vm.selectedOption
                },
                on: {
                  addDestination: _vm.onAddDestination,
                  update: _vm.onUpdateOption,
                  create: _vm.onCreateOption
                }
              })
            ],
            1
          )
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-muted" }, [
      _c("small", [
        _vm._v("Add IVR options to route callers to the right place.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }