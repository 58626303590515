var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "steps",
    {
      attrs: { steps: _vm.steps, name: "dialplan-create" },
      on: { complete: _vm.doComplete }
    },
    [
      _c(
        "template",
        { slot: "mode" },
        [_c("ModeStep", { attrs: { "dial-plan": _vm.dialPlan } })],
        1
      ),
      _c("template", { slot: "mode-complete" }, [
        _c("strong", [_vm._v(_vm._s(_vm.dialPlanMode))])
      ]),
      _c(
        "template",
        { slot: "basic" },
        [_c("BasicStep", { attrs: { "dial-plan": _vm.dialPlan } })],
        1
      ),
      _c("template", { slot: "basic-complete" }, [
        _vm._v(" " + _vm._s(_vm.dialPlan.getName()) + " ")
      ]),
      _c(
        "template",
        { slot: "ivr" },
        [
          _c("IvrStep", {
            attrs: { "dial-plan": _vm.dialPlan },
            on: { addDestination: _vm.onAddDestination }
          })
        ],
        1
      ),
      _c("template", { slot: "ivr-complete" }, [
        _vm._v(
          " IVR with " +
            _vm._s(_vm.dialPlan.getIvr().getOptions().length) +
            " options "
        )
      ]),
      _c(
        "template",
        { slot: "time" },
        [
          _c("TimeRoutingStep", {
            attrs: { dialPlan: _vm.dialPlan },
            on: { addDestination: _vm.onAddDestination }
          })
        ],
        1
      ),
      _c("template", { slot: "time-complete" }, [
        _vm._v(
          " Open " +
            _vm._s(_vm.timeConfigOpenDays.length) +
            " " +
            _vm._s(_vm.timeConfigOpenDays.length === 1 ? "day" : "days") +
            " a week "
        )
      ]),
      _c(
        "template",
        { slot: "confirm" },
        [_c("ConfirmStep", { attrs: { "dial-plan": _vm.dialPlan } })],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }