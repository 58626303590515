












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DialPlan, DialPlanMode } from '@/models/dialplan/dialplan'
import { TimeRouteDay } from '@/models/dialplan/time-configuration-schedule'

@Component
export default class Summary extends Vue {
  @Prop({ required: true }) dialPlan!: DialPlan
  data () {
    return {
      items: [
        {
          destinations: this.dialPlan.getDestinations(),
          ivr: this.dialPlan.getIvr(),
          timeConfig: this.dialPlan.getTimeConfiguration()
        }
      ]
    }
  }

  get isBasic (): boolean {
    return this.dialPlan.mode === DialPlanMode.Basic
  }

  get schedule (): { day: string; startTime: string; endTime: string }[] | null {
    if (!this.dialPlan.getTimeConfiguration()) {
      return null
    }

    const recurringSchedule = []

    for (const schedule of this.dialPlan.getTimeConfiguration().getRecurringOpeningSchedule()) {
      recurringSchedule.push({
        day: TimeRouteDay[schedule.getDay()],
        startTime: schedule.getStartTime(),
        endTime: schedule.getEndTime()
      })
    }

    return recurringSchedule
  }
}
