import { TimeConfigurationSchedule, TimeRouteDay } from './time-configuration-schedule'
import Model, { apiToModelTransformer, modelToApiTransformer } from '@/models/base'
import { JsonData } from '@/helpers/api'

export class TimeConfiguration extends Model {
  protected fieldDefinition = {
    timeZone: 'timeZone',
    closedDestination: 'closedDestination'
  }

  private timeZone = ''
  private closedDestination = ''
  private recurringOpeningSchedule: TimeConfigurationSchedule[] = []

  getTimeZone (): string {
    return this.timeZone
  }

  setTimeZone (timeZone: string) {
    this.timeZone = timeZone

    return this
  }

  getClosedDestination (): string {
    return this.closedDestination
  }

  setClosedDestination (destination: string) {
    this.closedDestination = destination

    return this
  }

  getRecurringOpeningSchedule (): TimeConfigurationSchedule[] {
    return this.recurringOpeningSchedule
  }

  setRecurringOpeningSchedule (recurringSchedule: TimeConfigurationSchedule[]) {
    this.recurringOpeningSchedule = recurringSchedule

    return this
  }

  addRecurringOpeningSchedule (schedule: TimeConfigurationSchedule) {
    this.recurringOpeningSchedule.push(schedule)

    return this
  }

  removeRecurringOpeningSchedule (schedule: TimeConfigurationSchedule) {
    const index = this.recurringOpeningSchedule.indexOf(schedule)

    if (index > -1) {
      this.recurringOpeningSchedule.splice(index, 1)
    }
  }

  removeRecurringOpeningScheduleForDay (day: TimeRouteDay) {
    const schedule = this.getScheduleForDay(day)

    if (schedule) {
      this.removeRecurringOpeningSchedule(schedule)
    }
  }

  getScheduleForDay (day: TimeRouteDay | null) {
    if (day) {
      return this.recurringOpeningSchedule.find(
        (schedule) => {
          return schedule.getDay() === day
        }
      )
    }
  }

  hasScheduleForDay (day: TimeRouteDay | null) {
    if (this.getScheduleForDay(day)) {
      return true
    }

    return false
  }

  static get days (): TimeRouteDay[] {
    return [
      TimeRouteDay.Monday,
      TimeRouteDay.Tuesday,
      TimeRouteDay.Wednesday,
      TimeRouteDay.Thursday,
      TimeRouteDay.Friday,
      TimeRouteDay.Saturday,
      TimeRouteDay.Sunday
    ]
  }

  fromApiTransformer (data: JsonData): TimeConfiguration {
    apiToModelTransformer(this, data, this.fieldDefinition)

    if (Array.isArray(data.recurringOpeningSchedule)) {
      for (const schedule of data.recurringOpeningSchedule) {
        const scheduleModel = new TimeConfigurationSchedule()
          .fromApiTransformer(schedule)

        this.addRecurringOpeningSchedule(scheduleModel)
      }
    }

    return this
  }

  toApiTransformer (): JsonData {
    const data = modelToApiTransformer(this, this.fieldDefinition)
    data.recurringOpeningSchedule = []

    for (const schedule of this.recurringOpeningSchedule) {
      data.recurringOpeningSchedule.push(schedule.toApiTransformer())
    }

    return data
  }
}
