var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "b-list-group",
        _vm._l(_vm.days, function(day) {
          return _c("TimeSchedule", {
            key: day,
            attrs: { day: day, "recurring-schedule": _vm.recurringSchedule },
            on: {
              removeSchedule: _vm.doRemoveSchedule,
              updateSchedule: _vm.doUpdateSchedule
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }