import Model, { apiToModelTransformer } from '@/models/base'
import { JsonData } from '@/helpers/api'
import MapItem from '@/models/map-item'

export default class DestinationMap extends Model {
  private id = 0
  private name = ''
  private mapItems: Array<Model | MapItem> = []

  protected fieldDefinition = {
    mapId: 'id',
    mapName: 'name'
  }

  setId (id: number) {
    this.id = id

    return this
  }

  getId (): number {
    return this.id
  }

  setName (name: string) {
    this.name = name
  }

  getName (): string | null {
    return this.name
  }

  setMapItems (items: Array<Model | MapItem>) {
    this.mapItems = items
  }

  getMapItems (): Array<Model | MapItem> {
    return this.mapItems
  }

  addMapItem (item: MapItem) {
    this.mapItems.push(item)
  }

  removeMapItem (item: MapItem) {
    const index = this.mapItems.indexOf(item)

    if (index > -1) {
      this.mapItems.splice(index, 1)
    }
  }

  fromApiTransformer (data: JsonData): DestinationMap {
    apiToModelTransformer(this, data, this.fieldDefinition)

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return undefined
  }
}
