














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class EmptyState extends Vue {
  @Prop(String) title?: string;
  @Prop(String) info?: string;
  @Prop(String) buttonText?: string;
  @Prop(String) buttonLink?: string;
}
