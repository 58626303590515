import { FilterCollection } from '@/helpers/filter'
import Model from '@/models/base'
import { Api, JsonData, RequestMethod } from '@/helpers/api'
import { AuthUtil } from '@/utils/auth'
import axios from 'axios'

export const DefaultLimit = 25

export type ApiParams = { [k: string]: string | number | string[] | number[] | null }

export default class Repository {
  protected api: Api
  protected apiRoute: string
  protected model: { new(): Model }
  protected accessProperty: string | null
  protected additionalParams: ApiParams = {}

  constructor (api: Api, apiRoute: string, model: { new(): Model }, accessProperty: string | null) {
    this.api = api
    this.apiRoute = apiRoute
    this.model = model
    this.accessProperty = accessProperty
  }

  async fetch (filterCollection: FilterCollection | null = null, limit = DefaultLimit, offset = 0): Promise<Model[]> {
    const data = []
    const response = await axios({
      ...{
        url: `${this.api}/${this.apiRoute}`,
        method: RequestMethod.GET,
        params: {
          limit,
          offset,
          filters: filterCollection ? filterCollection.toApi() : undefined,
          ...this.additionalParams
        }
      },
      ...this.requestConfig
    })

    let items = response.data

    if (this.accessProperty !== null) {
      items = items[this.accessProperty]
    }

    if (response.data && items) {
      for (const item of items) {
        data.push(
          new this.model().fromApiTransformer(item)
        )
      }
    }

    this.additionalParams = {}

    return data
  }

  async find (id: string | number): Promise<Model | null> {
    const response = await axios({
      ...{
        url: `${this.api}/${this.apiRoute}/${id}`,
        method: RequestMethod.GET,
        params: this.additionalParams
      },
      ...this.requestConfig
    })

    let data = response.data

    if (this.accessProperty !== null) {
      data = data[this.accessProperty]
    }

    if (!data) {
      return null
    }

    this.additionalParams = {}

    return new this.model().fromApiTransformer(data)
  }

  async create (model: Model): Promise<JsonData | null> {
    let data: JsonData | { [k: string]: JsonData | undefined } | undefined = model.toApiTransformer()

    if (this.accessProperty !== null) {
      data = {
        [this.accessProperty]: data
      }
    }

    const response = await axios({
      ...{
        url: `${this.api}/${this.apiRoute}`,
        method: RequestMethod.POST,
        data,
        params: this.additionalParams
      },
      ...this.requestConfig
    })

    if (!response.data) {
      return null
    }

    this.additionalParams = {}

    return response.data
  }

  async update (model: Model, id: string): Promise<JsonData | null> {
    const data: JsonData | { [k: string]: JsonData | undefined } | undefined = model.toApiTransformer()

    const response = await axios({
      ...{
        url: `${this.api}/${this.apiRoute}/${id}`,
        method: RequestMethod.PUT,
        data,
        params: this.additionalParams
      },
      ...this.requestConfig
    })
    this.additionalParams = {}

    return response.data
  }

  withParams (params: ApiParams) {
    this.additionalParams = params

    return this
  }

  protected get requestConfig (): { headers: { [k: string]: string | null } } | null {
    if (!AuthUtil.authenticatedHeaders) {
      return null
    }

    return {
      headers: AuthUtil.authenticatedHeaders[this.api]
    }
  }
}
