























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { IvrDigit, IvrOption } from '@/models/dialplan/ivr-option'
import { Destination } from '@/models/dialplan/destination'
import DestinationPicker from '@/components/wizard/DestinationPicker.vue'
import { Ivr } from '@/models/dialplan/ivr'

@Component({
  components: { DestinationPicker }
})
export default class Option extends Vue {
  @Prop({ default: null }) ivrOption!: IvrOption | null
  @Prop({ required: true }) destinations!: Destination[]
  @Prop({ required: true }) ivr!: Ivr
  newIvrOption = new IvrOption()

  private phonePadOptions = [
    {
      digit: IvrDigit.One,
      label: '...'
    },
    {
      digit: IvrDigit.Two,
      label: 'ABC'
    },
    {
      digit: IvrDigit.Three,
      label: 'DEF'
    },
    {
      digit: IvrDigit.Four,
      label: 'GHI'
    },
    {
      digit: IvrDigit.Five,
      label: 'JKL'
    },
    {
      digit: IvrDigit.Six,
      label: 'MNO'
    },
    {
      digit: IvrDigit.Seven,
      label: 'PQRS'
    },
    {
      digit: IvrDigit.Eight,
      label: 'TUV'
    },
    {
      digit: IvrDigit.Nine,
      label: 'WXYZ'
    },
    {
      digit: IvrDigit.Zero,
      label: '*'
    }
  ]

  created () {
    if (this.ivrOption) {
      this.newIvrOption
        .setDestination(this.ivrOption.getDestination())
        .setDigit(this.ivrOption.getDigit())
        .setIvrRef(this.ivrOption.getIvrRef())
    }
  }

  get ivrRef (): string | null {
    return this.newIvrOption.getIvrRef()
  }

  setOptionRef (ref: string) {
    this.newIvrOption.setIvrRef(ref)
  }

  setOptionDestination (destination: Destination) {
    this.newIvrOption.setDestination(destination.getDialPlanRef())
  }

  setDigit (digit: IvrDigit) {
    this.newIvrOption.setDigit(digit)
  }

  get isEdit () {
    return this.ivrOption !== null
  }

  get isDigitUsed () {
    return !!this.ivr.getOptions().find(
      (ivrOption) => {
        if (this.ivrOption !== null) {
          return (this.ivrOption.getDigit() !== ivrOption.getDigit()) &&
            (ivrOption.getDigit() === this.newIvrOption.getDigit())
        }

        return (ivrOption.getDigit() === this.newIvrOption.getDigit())
      }
    )
  }

  get isDigitValid () {
    if (this.newIvrOption.getDigit() === null) {
      return null
    }

    return this.isDigitUsed === false
  }

  get digitsVariant () {
    if (this.isDigitValid === false) {
      return 'outline-danger'
    }

    return 'outline-primary'
  }

  get isRefValid () {
    if (this.ivrRef) {
      return this.ivrRef.length >= 3
    }

    return null
  }

  get isValid () {
    return this.isRefValid && this.newIvrOption.getDestination() && this.isDigitValid
  }

  @Emit('addDestination')
  onAddDestination (destination: Destination) {
    return destination
  }

  doSave () {
    if (this.isEdit) {
      this.doUpdate()

      return
    }

    this.doCreate()
  }

  @Emit('update')
  doUpdate () {
    return {
      oldOption: this.ivrOption,
      newOption: this.newIvrOption
    }
  }

  @Emit('create')
  doCreate () {
    return this.newIvrOption
  }
}
