import Model, { apiToModelTransformer, modelToApiTransformer } from '@/models/base'
import { JsonData } from '@/helpers/api'

export default class MapItem extends Model {
  private key = ''
  private value = ''

  protected fieldDefinition = {
    itemKey: 'key',
    itemValue: 'value'
  }

  setKey (key: string) {
    this.key = key

    return this
  }

  getKey (): string {
    return this.key
  }

  setValue (value: string) {
    this.value = value

    return this
  }

  getValue (): string {
    return this.value
  }

  fromApiTransformer (data: JsonData): MapItem {
    apiToModelTransformer(this, data, this.fieldDefinition)

    return this
  }

  toApiTransformer (): JsonData | undefined {
    return modelToApiTransformer(this, this.fieldDefinition)
  }
}
