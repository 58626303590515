var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: {
            id: "dialPlanName",
            description:
              "Give your dial plan a name so that you can identify it later.",
            "invalid-feedback":
              "Dial plan name must contain only numbers and letters, with no spaces.",
            label: "Dial plan name",
            "label-for": "dialPlanName"
          }
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "dialPlanName",
              "aria-describedby": "dialPlanInvalid",
              state: _vm.isNameValid,
              trim: ""
            },
            model: {
              value: _vm.nameValue,
              callback: function($$v) {
                _vm.nameValue = $$v
              },
              expression: "nameValue"
            }
          })
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          attrs: {
            id: "dialPlanMessage",
            description:
              "Enter the filename of the welcome message you want to play when someone enters the dial plan.",
            "invalid-feedback":
              "Message filename should be at least 4 characters.",
            label: "Welcome Message Filename",
            "label-for": "dialPlanMessage"
          }
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "dialPlanMessage",
              state: _vm.isMessageValid,
              trim: ""
            },
            model: {
              value: _vm.messageValue,
              callback: function($$v) {
                _vm.messageValue = $$v
              },
              expression: "messageValue"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }