import Model, { apiToModelTransformer, modelToApiTransformer } from '@/models/base'
import { JsonData } from '@/helpers/api'

export enum IvrDigit { One = 1, Two = 2, Three = 3, Four = 4, Five = 5, Six = 6, Seven = 7, Eight = 8, Nine = 9, Zero = 0 }

export class IvrOption extends Model {
  protected fieldDefinition = {
    digit: 'digit',
    ivrRef: 'ivrRef',
    destination: 'destination'
  }

  private digit: IvrDigit | null = null
  private ivrRef = ''
  private destination = ''

  getDigit (): IvrDigit | null {
    return this.digit
  }

  setDigit (digit: IvrDigit | null) {
    this.digit = digit

    return this
  }

  getIvrRef (): string {
    return this.ivrRef
  }

  setIvrRef (ref: string) {
    this.ivrRef = ref

    return this
  }

  getDestination (): string {
    return this.destination
  }

  setDestination (destination: string) {
    this.destination = destination

    return this
  }

  fromApiTransformer (data: JsonData): IvrOption {
    apiToModelTransformer(this, data, this.fieldDefinition)

    return this
  }

  toApiTransformer (): JsonData {
    return modelToApiTransformer(this, this.fieldDefinition)
  }
}
