var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialPlan
    ? _c(
        "div",
        [
          _c("b-table", {
            attrs: {
              "table-variant": "light",
              stacked: "sm",
              items: _vm.items
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(destinations)",
                  fn: function(data) {
                    return _vm._l(data.value, function(destination, key) {
                      return _c("div", { key: destination.getDialPlanRef() }, [
                        _c("div", [
                          _c("h6", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(key) +
                                  ": " +
                                  _vm._s(destination.getDialPlanRef())
                              )
                            ])
                          ]),
                          destination.getMapItemKey()
                            ? _c("div", [
                                _vm._v(" Map Item: "),
                                _c("strong", [
                                  _vm._v(_vm._s(destination.getMapItemKey()))
                                ])
                              ])
                            : _vm._e(),
                          destination.getPlaybackFilename()
                            ? _c("div", [
                                _vm._v(" Sound File: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(destination.getPlaybackFilename())
                                  )
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _c("hr")
                      ])
                    })
                  }
                },
                {
                  key: "cell(ivr)",
                  fn: function(data) {
                    return [
                      _c("h6", [
                        _vm._v("Playback Filename: "),
                        _c("strong", [
                          _vm._v(_vm._s(data.value.getIvrPlaybackFilename()))
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "mb-5" },
                        [
                          _c("h5", [_vm._v("IVR Options")]),
                          _vm._l(data.value.options, function(option, index) {
                            return _c("div", { key: index }, [
                              _c("hr"),
                              _c("h6", [
                                _vm._v(" " + _vm._s(option.getDigit()) + ": "),
                                _c("strong", [
                                  _vm._v(_vm._s(option.getIvrRef()))
                                ]),
                                _vm._v(
                                  " - Destination: " +
                                    _vm._s(option.getDestination()) +
                                    " "
                                )
                              ])
                            ])
                          })
                        ],
                        2
                      )
                    ]
                  }
                },
                {
                  key: "cell(timeConfig)",
                  fn: function(data) {
                    return [
                      !_vm.isBasic
                        ? _c(
                            "div",
                            [
                              _c("h5", [_vm._v("Recurring Open Schedule")]),
                              _c("b-table", {
                                attrs: { striped: "", items: _vm.schedule }
                              }),
                              _c("h6", [
                                _vm._v("Timezone: "),
                                _c("strong", [
                                  _vm._v(_vm._s(data.value.getTimeZone()))
                                ])
                              ]),
                              _c("h6", [
                                _vm._v("Closed Destination: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(data.value.getClosedDestination())
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        : _c(
                            "p",
                            {
                              staticClass: "text-muted",
                              attrs: { id: "noTimeConfig" }
                            },
                            [
                              _vm._v(
                                " Basic DialPlans do not support Time Configuration. "
                              )
                            ]
                          )
                    ]
                  }
                }
              ],
              null,
              false,
              3704832747
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }