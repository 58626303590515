










































































import { Component, Emit } from 'vue-property-decorator'
import BaseStep from '@/components/wizard/BaseStep.vue'
import { IvrOption } from '@/models/dialplan/ivr-option'
import { Ivr as IvrModel } from '@/models/dialplan/ivr'
import Option from '@/components/wizard/Ivr/Option.vue'
import { Destination } from '@/models/dialplan/destination'

@Component({
  components: {
    Option
  }
})
export default class IvrStep extends BaseStep {
  completedValue: string | null = null
  selectedOption: IvrOption | null = null

  get ivr (): IvrModel | null {
    return this.dialPlan.getIvr()
  }

  get ivrOptions () {
    if (this.ivr) {
      return this.ivr.getOptions()
    }

    return []
  }

  created () {
    if (!this.dialPlan.getIvr()) {
      this.dialPlan.setIvr(new IvrModel())
    }
  }

  get allowedOptions (): number | null {
    if (this.isBasic) {
      return 5
    }

    if (this.isAdvanced) {
      return 9
    }

    return null
  }

  get playbackFilenameValue () {
    if (this.ivr) {
      return this.ivr.getIvrPlaybackFilename()
    }

    return ''
  }

  set playbackFilenameValue (value: string) {
    if (this.ivr) {
      this.ivr.setIvrPlaybackFilename(value)
    }
  }

  get isPlaybackFilenameValid () {
    if (this.playbackFilenameValue === '') {
      return null
    }

    return this.playbackFilenameValue.length >= 4
  }

  get isValid () {
    return this.isPlaybackFilenameValid && this.ivrOptions.length > 0
  }

  doCancelOption () {
    this.selectedOption = null
    this.$bvModal.hide('add-option')
  }

  doConfirm () {
    this.completedValue = ''
  }

  doCreateOption () {
    this.$bvModal.show('add-option')
  }

  doEditOption (option: IvrOption) {
    this.selectedOption = option
    this.$bvModal.show('add-option')
  }

  onCreateOption (option: IvrOption) {
    if (this.ivr && option) {
      if (this.ivr.hasOption(option) === false) {
        this.ivr.addOption(option)
      }

      this.$bvModal.hide('add-option')
    }
  }

  onUpdateOption ({ oldOption, newOption }: {[k: string]: IvrOption}) {
    oldOption.setIvrRef(newOption.getIvrRef())
      .setDigit(newOption.getDigit())
      .setDestination(newOption.getDestination())

    this.$bvModal.hide('add-option')
    this.selectedOption = null
  }

  doRemoveOption (option: IvrOption) {
    if (this.ivr) {
      this.ivr.removeOption(option)
      this.$forceUpdate()
    }
  }

  @Emit('addDestination')
  onAddDestination (destination: Destination) {
    return destination
  }
}
