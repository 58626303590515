






















import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { TimeConfigurationSchedule, TimeRouteDay } from '@/models/dialplan/time-configuration-schedule'

const defaultStartTime = '09:00'
const defaultEndTime = '17:00'

@Component
export default class TimeSchedule extends Vue {
  @Prop({ required: true }) day!: TimeRouteDay
  @Prop({ required: true }) recurringSchedule!: TimeConfigurationSchedule[]

  @Watch('recurringSchedule', { deep: true })
  doUpdateSchedule (recurringSchedule: TimeConfigurationSchedule[]) {
    const scheduleForDay = recurringSchedule.find(
      (schedule) => {
        return schedule.getDay() === this.day
      }
    )

    if (scheduleForDay) {
      this.timeSchedule = new TimeConfigurationSchedule()
        .setDay(this.day)
        .setStartTime(scheduleForDay.getStartTime())
        .setEndTime(scheduleForDay.getEndTime())
    }
  }

  private timeSchedule: TimeConfigurationSchedule | null = null

  get isClosed () {
    return !this.timeSchedule
  }

  get existing () {
    return this.recurringSchedule.find(
      (schedule) => {
        return schedule.getDay() === this.day
      }
    )
  }

  get dayLabel () {
    return TimeRouteDay[this.day]
  }

  @Emit('updateSchedule')
  doUpdateTimeConfiguration () {
    return this.timeSchedule
  }

  doUpdateStartTime (value: string) {
    if (this.timeSchedule) {
      this.timeSchedule.setStartTime(value)

      this.doUpdateTimeConfiguration()
    }
  }

  doUpdateEndTime (value: string) {
    if (this.timeSchedule) {
      this.timeSchedule.setEndTime(value)
      this.doUpdateTimeConfiguration()
    }
  }

  doAddTimeConfiguration () {
    this.timeSchedule = new TimeConfigurationSchedule()
      .setDay(this.day)
      .setStartTime(defaultStartTime)
      .setEndTime(defaultEndTime)

    return this.doUpdateTimeConfiguration()
  }

  @Emit('removeSchedule')
  doRemoveTimeConfiguration () {
    this.timeSchedule = null

    return this.day
  }

  created () {
    if (this.existing) {
      this.timeSchedule = new TimeConfigurationSchedule()
        .setDay(this.day)
        .setStartTime(this.existing.getStartTime())
        .setEndTime(this.existing.getEndTime())
    }
  }
}
