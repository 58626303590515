



























































import { Component, Vue } from 'vue-property-decorator'
import { DialPlan } from '@/models/dialplan/dialplan'
import Repository from '@/repos/base'
import { Api } from '@/helpers/api'
import Model from '@/models/base'
import Wizard from '@/components/wizard/Wizard.vue'

@Component({
  components: {
    Wizard
  }
})
export default class Create extends Vue {
  protected dialPlan: Model | DialPlan = new DialPlan()
  protected repo = new Repository(Api.DialPlanService, 'dialplans', DialPlan, null)
  protected isSaving = false
  private createError: string | null = null

  doCloseError () {
    this.$bvModal.hide('saving-modal')
    this.createError = null
  }

  async doOpenSavingModal () {
    this.isSaving = true
    this.$bvModal.show('saving-modal')

    await this.doSaveDialPlan()

    this.isSaving = false
  }

  async doSaveDialPlan () {
    const [result] = await Promise.allSettled([
      this.repo.create(this.dialPlan),
      new Promise(resolve => setTimeout(resolve, 1000))
    ])

    if (
      (result.status === 'fulfilled') &&
        (result.value && result.value.data) &&
        typeof result.value.data === 'object'
    ) {
      const data = result.value.data

      if (!Array.isArray(data) && typeof data.id === 'string') {
        this.dialPlan.setId(data.id)
      }
    }

    if (result.status === 'rejected' && result.reason) {
      this.createError = result.reason.response.data.error
    }
  }
}
