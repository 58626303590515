

















































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { Destination } from '@/models/dialplan/destination'
import DestinationView from '@/components/wizard/DestinationView.vue'
import MapItemPicker from '@/components/wizard/DestinationPicker/MapItemPicker.vue'
import MapItem from '@/models/map-item'

@Component({
  components: {
    DestinationView,
    MapItemPicker
  }
})
export default class DestinationPicker extends Vue {
  @Prop({ required: true }) destinations!: { [index: string]: Destination }
  @Prop(String) destination!: string | null
  @Prop({ default: 'Destination', type: String }) label!: string
  @Prop({ type: String }) helpText!: string
  selectedDestination: Destination | null = null
  newDestination: Destination | null = null
  pickerVisible = false

  doTogglePicker (visible: boolean) {
    this.pickerVisible = visible

    if (visible === false) {
      this.doCancelCreate()
    }

    if (visible === true && this.availableDestinations.length === 0) {
      this.doCreateDestination()
    }
  }

  get isDialPlanRefValid () {
    if (this.newDestinationRef !== null && this.newDestinationRef !== '') {
      return this.newDestinationRef.length >= 1 && !Object.keys(this.destinations).find(
        (destinationRef) => {
          return destinationRef === this.newDestinationRef
        }
      )
    }

    return null
  }

  get newDestinationRef () {
    return this.newDestination ? this.newDestination.getDialPlanRef() : null
  }

  get isNewDestinationValid () {
    return this.isDialPlanRefValid && (this.newDestination?.getMapItem() || this.newDestination?.getPlaybackFilename())
  }

  get selectedMapItemKeys () {
    return Object.entries(this.destinations).forEach(([key, destination]) => {
      return destination.getMapItemKey() !== undefined
    })
  }

  created () {
    this.$nextTick(
      () => {
        if (this.destination && this.destinations[this.destination]) {
          this.selectedDestination = this.destinations[this.destination]
        }
      }
    )
  }

  doCreateDestination () {
    this.newDestination = new Destination()
  }

  setDialPlanRef (value: string) {
    if (this.newDestination) {
      this.newDestination.setDialPlanRef(value)
    }
  }

  setMapItem (value: MapItem) {
    if (this.newDestination) {
      this.newDestination.setMapItem(value)
    }
  }

  doRemoveMapItem () {
    if (this.newDestination) {
      this.newDestination.setMapItem(null)
    }
  }

  setPlaybackFilename (value: string) {
    if (this.newDestination) {
      this.newDestination.setPlaybackFilename(value)
    }
  }

  doCancelCreate () {
    this.newDestination = null
  }

  get availableDestinations () {
    return Object.entries(this.destinations).filter(
      (destination) => {
        const [index, dest] = destination
        return dest !== this.selectedDestination
      }
    )
  }

  @Emit('addDestination')
  doAddDestination () {
    const newDestination = this.newDestination
    if (newDestination) {
      this.doSelectDestination(newDestination)
    }

    this.newDestination = null

    return newDestination
  }

  @Emit('selectDestination')
  doSelectDestination (destination: Destination) {
    this.selectedDestination = destination
    this.doTogglePicker(false)

    return destination
  }
}
