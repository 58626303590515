var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.installationId
        ? _c(
            "b-container",
            { staticClass: "h-100" },
            [
              _c(
                "b-row",
                { attrs: { "align-h": "center", "align-v": "center" } },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "5" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mt-5" },
                        [
                          _c("h3", [
                            _vm._v("Welcome to the Call Flow Manager")
                          ]),
                          _c("p", [
                            _vm._v(
                              "To get started, choose an installation below:"
                            )
                          ]),
                          _c("InstallationPicker", {
                            attrs: { align: "center" },
                            on: { selected: _vm.getDialPlans }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.isLoading
        ? _c(
            "div",
            {
              staticClass:
                "vh-100 d-flex align-items-center justify-content-center mb-3"
            },
            [_c("b-spinner", { attrs: { label: "Loading..." } })],
            1
          )
        : !_vm.hasDialplans
        ? _c("EmptyState", {
            attrs: {
              title: "Let's get you started.",
              info:
                "You don't have any dial plans for this Installation yet, creating one takes just a few minutes. Click the button below to get started.",
              buttonText: "Create one now",
              buttonLink: "create"
            }
          })
        : _c(
            "b-container",
            [
              _c(
                "b-row",
                {
                  staticClass: "my-3",
                  attrs: { "align-h": "center", "align-v": "center" }
                },
                [
                  _c("b-col"),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "primary", to: "create" }
                        },
                        [_vm._v("Create new dial plan")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { attrs: { "align-self": "center", cols: "12" } },
                    [
                      _c(
                        "b-row",
                        {
                          staticClass:
                            "my-3 d-flex justify-content-between align-items-center"
                        },
                        [
                          _c("b-col", [_c("h2", [_vm._v("Dial plans")])]),
                          _c(
                            "b-col",
                            { attrs: { cols: "6", lg: "4" } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "search",
                                      id: "filterInput",
                                      placeholder: "Type to Search"
                                    },
                                    model: {
                                      value: _vm.filter,
                                      callback: function($$v) {
                                        _vm.filter = $$v
                                      },
                                      expression: "filter"
                                    }
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { disabled: !_vm.filter },
                                          on: {
                                            click: function($event) {
                                              _vm.filter = ""
                                            }
                                          }
                                        },
                                        [_vm._v("Clear")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "rounded border shadow-sm bg-light overflow-hidden"
                        },
                        [
                          _c("b-table", {
                            attrs: {
                              items: _vm.dialPlans,
                              fields: _vm.fields,
                              "sort-by": _vm.sortBy,
                              filter: _vm.filter,
                              "table-class": "mb-0",
                              fixed: "",
                              borderless: "",
                              striped: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(actions)",
                                fn: function(row) {
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "ml-auto float-right",
                                        attrs: { variant: "link" },
                                        on: {
                                          click: function($event) {
                                            return _vm.editDialPlan(row)
                                          }
                                        }
                                      },
                                      [_vm._v(" Edit ")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "ml-auto float-right",
                                        attrs: { variant: "link", size: "sm" },
                                        on: { click: row.toggleDetails }
                                      },
                                      [_vm._v(" View ")]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "row-details",
                                fn: function(row) {
                                  return [
                                    _c("Summary", {
                                      attrs: { "dial-plan": row.item }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }