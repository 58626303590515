




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Destination } from '@/models/dialplan/destination'

@Component
export default class DestinationView extends Vue {
  @Prop({ required: true }) destination!: Destination
  @Prop(Boolean) selected!: boolean
  @Prop(Boolean) button!: boolean
}
