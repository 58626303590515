



































































import { Component } from 'vue-property-decorator'
import { AuthUtil } from '@/utils/auth'
import Create from '@/views/Create.vue'
import Repository from '@/repos/base'
import { Api } from '@/helpers/api'
import { DialPlan } from '@/models/dialplan/dialplan'
import Wizard from '@/components/wizard/Wizard.vue'

@Component({
  components: {
    Wizard
  }
})
export default class Edit extends Create {
  private editError: string | null = null
  private isLoading = true
  protected repo = new Repository(Api.DialPlanService, 'dialplans', DialPlan, 'data')

  async created () {
    // Get it from the API otherwise
    if (AuthUtil.installationId && this.$attrs.dialPlanId) {
      await this.getDialPlan()
      return
    }

    this.dialPlan = this.$store.state.dialPlan
  }

  async doOpenSavingModal () {
    this.isSaving = true
    this.$bvModal.show('saving-modal')

    await this.doSaveDialPlan()

    this.isSaving = false
  }

  async doSaveDialPlan () {
    const [result] = await Promise.allSettled([
      this.repo.update(this.dialPlan, this.dialPlan.getId()),
      new Promise(resolve => setTimeout(resolve, 1000))
    ])

    if (
      (result.status === 'fulfilled') &&
      (result.value && result.value.data) &&
      typeof result.value.data === 'object'
    ) {
      const data = result.value.data

      if (!Array.isArray(data) && typeof data.id === 'string') {
        this.dialPlan.setId(data.id)
      }
    }

    if (result.status === 'rejected' && result.reason) {
      this.editError = result.reason.response.data.error
    }
  }

  async getDialPlan () {
    const dialPlan = await this.repo
      .withParams({ installationId: AuthUtil.installationId })
      .find(this.$attrs.dialPlanId)

    if (dialPlan) {
      this.$store.commit('setDialPlan', dialPlan)
      this.$store.commit('setNewDialPlanMode', dialPlan.mode)
      this.isLoading = false
      this.dialPlan = dialPlan
    }
  }
}
