var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "vh-100", attrs: { id: "emptyState" } },
    [
      _c(
        "b-row",
        {
          staticClass: "justify-content-center",
          attrs: { "align-v": "center" }
        },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "b-jumbotron",
                { attrs: { header: _vm.title, lead: _vm.info } },
                [
                  _vm._t("default"),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        size: "lg",
                        to: _vm.buttonLink
                      }
                    },
                    [_vm._v(_vm._s(_vm.buttonText))]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }