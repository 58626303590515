var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          attrs: {
            id: "timeZone",
            description:
              "The time zone that your schedule should reflect. For example 'Europe/London'.",
            label: "Time zone",
            "label-for": "timeZone"
          }
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "timeZone",
              state: _vm.isTimeZoneValid,
              list: "time-zone-datalist",
              trim: ""
            },
            model: {
              value: _vm.timeZone,
              callback: function($$v) {
                _vm.timeZone = $$v
              },
              expression: "timeZone"
            }
          }),
          _c(
            "datalist",
            { attrs: { id: "time-zone-datalist" } },
            _vm._l(_vm.commonTimeZones, function(timeZone) {
              return _c("option", { key: timeZone }, [_vm._v(_vm._s(timeZone))])
            }),
            0
          ),
          _c(
            "b-form-invalid-feedback",
            { attrs: { state: _vm.isTimeZoneValid } },
            [
              _vm._v(" Ensure that you enter a valid time zone name. "),
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://en.wikipedia.org/wiki/List_of_tz_database_time_zones",
                    target: "_blank"
                  }
                },
                [
                  _vm._v(
                    " Check this website for valid time zones (TZ database name column). "
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _c("DestinationPicker", {
        attrs: {
          label: "Closed destination",
          "help-text":
            "Choose the destination that will be used outside of opening hours.",
          destinations: _vm.destinations,
          destination: _vm.closedDestination
        },
        on: {
          selectDestination: _vm.doSetClosedDestination,
          addDestination: _vm.doAddDestination
        }
      }),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _vm._m(0),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal:quick-fill",
                  arg: "quick-fill"
                },
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: { hover: true }
                }
              ],
              attrs: { variant: "outline-primary", title: "Quick Fill" }
            },
            [_c("b-icon-lightning-fill")],
            1
          )
        ],
        1
      ),
      _c("RecurringSchedule", {
        attrs: { "dial-plan": _vm.dialPlan },
        on: { updateSchedule: _vm.onUpdateSchedule }
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: "quick-fill",
            title: "Quick fill schedule",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "link" },
                      on: { click: _vm.doCancelQuickFill }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.isQuickFillValid,
                        variant: "success"
                      },
                      on: { click: _vm.doQuickFill }
                    },
                    [_vm._v(" Done ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center mb-3"
            },
            [
              _c("b-form-select", {
                attrs: { options: _vm.daySelectOptions },
                model: {
                  value: _vm.quickFillStartDay,
                  callback: function($$v) {
                    _vm.quickFillStartDay = $$v
                  },
                  expression: "quickFillStartDay"
                }
              }),
              _c("span", { staticClass: "px-5 text-center" }, [_vm._v("to")]),
              _c("b-form-select", {
                attrs: { options: _vm.daySelectOptions },
                model: {
                  value: _vm.quickFillEndDay,
                  callback: function($$v) {
                    _vm.quickFillEndDay = $$v
                  },
                  expression: "quickFillEndDay"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center"
            },
            [
              _c("b-form-input", {
                attrs: { type: "time" },
                model: {
                  value: _vm.quickFillStartTime,
                  callback: function($$v) {
                    _vm.quickFillStartTime = $$v
                  },
                  expression: "quickFillStartTime"
                }
              }),
              _c("span", { staticClass: "px-5 text-center" }, [_vm._v("to")]),
              _c("b-form-input", {
                attrs: { type: "time" },
                model: {
                  value: _vm.quickFillEndTime,
                  callback: function($$v) {
                    _vm.quickFillEndTime = $$v
                  },
                  expression: "quickFillEndTime"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", [_vm._v("Recurring Schedule")]),
      _c("p", { staticClass: "text-muted" }, [
        _c("small", [
          _vm._v(
            "Choose the days and times that this dial plan will be active. When closed, all calls will be routed to the closed destination."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }