import MapItem from '@/models/map-item'
import Model, { apiToModelTransformer, modelToApiTransformer } from '@/models/base'
import { JsonData } from '@/helpers/api'

export class Destination extends Model {
    protected fieldDefinition = {
      dialPlanRef: 'dialPlanRef',
      playbackFilename: 'playbackFilename'
    }

    private dialPlanRef = ''
    private mapItem: MapItem | null = null
    private playbackFilename = ''

    getDialPlanRef (): string {
      return this.dialPlanRef
    }

    setDialPlanRef (dialPlanRef: string) {
      this.dialPlanRef = dialPlanRef

      return this
    }

    setMapItem (mapItem: MapItem | null) {
      this.mapItem = mapItem

      return this
    }

    getMapItem (): MapItem | null {
      return this.mapItem
    }

    getMapItemKey (): string | undefined {
      return this.mapItem?.getKey() || undefined
    }

    getPlaybackFilename (): string {
      return this.playbackFilename
    }

    setPlaybackFilename (playbackFilename: string) {
      this.playbackFilename = playbackFilename

      return this
    }

    fromApiTransformer (data: JsonData): Destination {
      apiToModelTransformer(this, data, this.fieldDefinition)

      this.mapItem = new MapItem()
        .fromApiTransformer({ itemKey: data.mapItemKey })

      return this
    }

    toApiTransformer (): JsonData {
      return {
        ...modelToApiTransformer(this, this.fieldDefinition),
        mapItemKey: this.getMapItemKey()
      }
    }
}
