var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", attrs: { id: "summary" } },
    [
      _vm.dialPlan
        ? _c(
            "b-container",
            [
              _c(
                "b-row",
                {
                  staticClass: "my-3",
                  attrs: { "align-h": "center", "align-v": "center" }
                },
                [
                  _c("b-col", [
                    _c("h2", [
                      _c(
                        "a",
                        {
                          staticClass: "btn-link",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.$router.go(-1)
                            }
                          }
                        },
                        [_vm._v("Dial plans")]
                      ),
                      _vm._v(" / " + _vm._s(_vm.dialPlan.getName()))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    {
                      attrs: {
                        id: "loginCol",
                        "align-self": "center",
                        cols: "12"
                      }
                    },
                    [
                      _c(
                        "b-card",
                        [
                          _c("h4", [
                            _vm._v("Welcome Playback Filename: "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.dialPlan.getWelcomePlaybackFilename()
                                )
                              )
                            ])
                          ]),
                          _c("Summary", {
                            attrs: { "dial-plan": _vm.dialPlan }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }