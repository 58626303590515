import Model, { ApiFieldDefinition, apiToModelTransformer } from '@/models/base'
import { JsonData } from '@/helpers/api'

export default class Installation extends Model {
  // TODO: Use Data Dictionary
  protected fieldDefinition: ApiFieldDefinition = {
    igrp: 'id',
    igrpName: 'name'
  }

  private id = ''
  private name = ''

  getId (): string {
    return this.id
  }

  setId (id: string): Installation {
    this.id = id

    return this
  }

  getName (): string {
    return this.name
  }

  setName (name: string): Installation {
    this.name = name

    return this
  }

  fromApiTransformer (data: JsonData): Installation {
    apiToModelTransformer(this, data, this.fieldDefinition)

    return this
  }

  toApiTransformer (): undefined {
    return undefined
  }
}
