var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group",
    [
      _c(
        "b-list-group-item",
        {
          staticClass: "flex-row align-items-start step-title",
          class: { disabled: _vm.disabled },
          attrs: { button: !_vm.disabled && !_vm.isActive && _vm.complete },
          on: { click: _vm.doSelectStep }
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { "align-v": "center" } },
                [
                  _c("b-avatar", {
                    attrs: {
                      variant: _vm.complete ? "success" : "primary",
                      text: _vm.complete ? null : "" + _vm.number,
                      icon: _vm.complete ? "check2" : null
                    }
                  }),
                  _c(
                    "b-col",
                    { attrs: { cols: "11" } },
                    [
                      _c(
                        "h5",
                        [
                          _vm._v(_vm._s(_vm.title) + " "),
                          _vm.pill
                            ? _c("b-badge", { attrs: { pill: "" } }, [
                                _vm._v(_vm._s(_vm.pill))
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.complete
                        ? _vm._t("completedValue")
                        : _vm.description
                        ? _c("p", [_vm._v(_vm._s(_vm.description))])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            id: _vm.collapseItemId,
            visible: _vm.isActive,
            accordion: _vm.accordion,
            role: "tabpanel"
          }
        },
        [
          _c(
            "b-list-group-item",
            { staticClass: "flex-row align-items-start" },
            [
              _c(
                "b-container",
                { staticClass: "step-content" },
                [_vm.isActive ? _vm._t("default") : _vm._e()],
                2
              ),
              _c(
                "b-container",
                { staticClass: "step-buttons" },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-content": "end", "align-h": "end" } },
                    [
                      !_vm.isFirstStep
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "link" },
                              on: { click: _vm.doPrevious }
                            },
                            [_vm._v(" Previous ")]
                          )
                        : _vm._e(),
                      _vm.isFinalStep
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: !_vm.isValid,
                                variant: "success"
                              },
                              on: { click: _vm.doComplete }
                            },
                            [_vm._v(" Complete ")]
                          )
                        : _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: !_vm.isValid,
                                variant: "success"
                              },
                              on: { click: _vm.doComplete }
                            },
                            [_vm._v(" Next ")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }