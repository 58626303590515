var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group-item",
    {
      staticClass:
        "d-flex justify-content-between align-items-center time-schedule"
    },
    [
      _c("span", { staticClass: "day" }, [
        _vm._v(" " + _vm._s(_vm.dayLabel) + " ")
      ]),
      _c("span", { staticClass: "state text-center" }, [
        !_vm.isClosed
          ? _c("strong", { staticClass: "text-success" }, [_vm._v("OPEN")])
          : _c("strong", { staticClass: "text-danger" }, [_vm._v("CLOSED")])
      ]),
      !_vm.isClosed
        ? _c(
            "span",
            {
              staticClass:
                "time text-center d-flex justify-content-between align-items-center"
            },
            [
              _c(
                "label",
                { staticClass: "my-0 mx-2", attrs: { for: "startTime" } },
                [_vm._v("From")]
              ),
              _c("b-form-input", {
                attrs: {
                  type: "time",
                  id: "startTime",
                  name: "startTime",
                  value: _vm.timeSchedule.getStartTime()
                },
                on: { input: _vm.doUpdateStartTime }
              }),
              _c(
                "label",
                { staticClass: "my-0 mx-2", attrs: { for: "endTime" } },
                [_vm._v("To")]
              ),
              _c("b-form-input", {
                attrs: {
                  type: "time",
                  id: "endTime",
                  name: "endTime",
                  value: _vm.timeSchedule.getEndTime()
                },
                on: { input: _vm.doUpdateEndTime }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        [
          _vm.isClosed
            ? _c(
                "b-button",
                {
                  attrs: { variant: "outline-success" },
                  on: { click: _vm.doAddTimeConfiguration }
                },
                [_vm._v("Add hours")]
              )
            : _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: { click: _vm.doRemoveTimeConfiguration }
                },
                [_vm._v("Closed")]
              )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }