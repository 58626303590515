var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        [
          _c("label", { staticClass: "w-100" }, [_vm._v(_vm._s(_vm.label))]),
          _vm.helpText
            ? _c("p", { staticClass: "text-muted" }, [
                _c("small", [_vm._v(_vm._s(_vm.helpText))])
              ])
            : _vm._e(),
          _c(
            "b-list-group",
            [
              _vm.selectedDestination
                ? _c("DestinationView", {
                    staticClass: "mb-3",
                    attrs: { destination: _vm.selectedDestination }
                  })
                : _vm._e()
            ],
            1
          ),
          !_vm.pickerVisible
            ? _c(
                "b-button",
                {
                  staticClass: "mb-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.doTogglePicker(true)
                    }
                  }
                },
                [
                  _vm.selectedDestination
                    ? [_vm._v("Change destination")]
                    : [_vm._v("Choose a destination")]
                ],
                2
              )
            : _vm._e(),
          _c(
            "b-collapse",
            { attrs: { id: "choose-destination", visible: _vm.pickerVisible } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between mb-3" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function($event) {
                          return _vm.doTogglePicker(false)
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        pressed: _vm.newDestination ? true : false,
                        disabled: _vm.newDestination ? true : false
                      },
                      on: { click: _vm.doCreateDestination }
                    },
                    [_c("b-icon-plus"), _vm._v(" New destination ")],
                    1
                  )
                ],
                1
              ),
              _vm.availableDestinations && !_vm.newDestination
                ? _c(
                    "b-list-group",
                    { staticClass: "w-100" },
                    _vm._l(_vm.availableDestinations, function(ref) {
                      var key = ref[0]
                      var destination = ref[1]
                      return _c("DestinationView", {
                        key: key,
                        attrs: { destination: destination, button: true },
                        on: {
                          click: function($event) {
                            return _vm.doSelectDestination(destination)
                          }
                        }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "add-destination",
            title: "Add destination",
            visible: _vm.newDestination !== null,
            width: "30em",
            backdrop: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            right: "",
            "no-header": "",
            shadow: ""
          }
        },
        [
          _vm.newDestination
            ? _c(
                "div",
                { staticClass: "px-3 py-2" },
                [
                  _c("h4", [_vm._v("New destination")]),
                  _c("p", { staticClass: "text-muted" }, [
                    _c("small", [
                      _vm._v(
                        "Create a destination that can be used throughout the dial plan."
                      )
                    ])
                  ]),
                  _c(
                    "b-list-group",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-list-group-item",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "dialplanRef",
                                description:
                                  "A reference to use for this destination in the dial plan.",
                                label: "Reference",
                                "label-for": "dialplanRef"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "dialplanRef",
                                  value: _vm.newDestination.getDialPlanRef(),
                                  state: _vm.isDialPlanRefValid,
                                  trim: ""
                                },
                                on: { input: _vm.setDialPlanRef }
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { state: _vm.isDialPlanRefValid } },
                                [
                                  _vm._v(
                                    " Reference is required, and must not be used by any other destination on this dial plan. "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "playbackFilename",
                                description:
                                  "The filename of a message to play when the caller reaches the destination.",
                                label: "Playback Filename",
                                "label-for": "playbackFilename"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "playbackFilename",
                                  value: _vm.newDestination.getPlaybackFilename(),
                                  trim: ""
                                },
                                on: { input: _vm.setPlaybackFilename }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "mapItemKey",
                                label: "Map Item",
                                "label-for": "mapItemKey"
                              }
                            },
                            [
                              _vm.newDestination.getMapItem()
                                ? _c(
                                    "b-list-group-item",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between align-items-center"
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "small",
                                          { staticClass: "w-100 d-block" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.newDestination
                                                  .getMapItem()
                                                  .getKey()
                                              ) + " "
                                            ),
                                            _c("b-icon-arrow-bar-right")
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.newDestination
                                                .getMapItem()
                                                .getValue()
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c(
                                        "b-button",
                                        { on: { click: _vm.doRemoveMapItem } },
                                        [_c("b-icon-pencil")],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c("MapItemPicker", {
                                    attrs: {
                                      "selected-map-item-keys":
                                        _vm.selectedMapItemKeys
                                    },
                                    on: { select: _vm.setMapItem }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex w-100 flex-row-reverse" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    disabled: !_vm.isNewDestinationValid,
                                    variant: "success"
                                  },
                                  on: { click: _vm.doAddDestination }
                                },
                                [_vm._v(" Create ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "link" },
                                  on: { click: _vm.doCancelCreate }
                                },
                                [_vm._v(" Cancel ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }