

















import { Component } from 'vue-property-decorator'
import BaseStep from '@/components/wizard/BaseStep.vue'
import { DialPlanMode } from '@/models/dialplan/dialplan'

@Component
export default class ModeStep extends BaseStep {
  get dialPlanMode () {
    return this.$store.state.newDialPlanMode
  }

  set dialPlanMode (value: DialPlanMode) {
    this.$store.commit('setNewDialPlanMode', value)
  }

  created () {
    if (this.$store.state.newDialPlanMode) {
      this.dialPlanMode = this.$store.state.newDialPlanMode
    }
  }

  get isModeBasic (): boolean {
    return this.dialPlanMode === DialPlanMode.Basic
  }

  get isModeAdvanced (): boolean {
    return this.dialPlanMode === DialPlanMode.Advanced
  }

  doSelectBasic () {
    this.dialPlanMode = DialPlanMode.Basic
  }

  doSelectAdvanced () {
    this.dialPlanMode = DialPlanMode.Advanced
  }
}
