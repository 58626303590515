var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    id: "ivrReference",
                    description: "Enter the IVR reference for this option.",
                    label: "IVR Reference",
                    "label-for": "ivrReference"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "ivrReference",
                      value: _vm.newIvrOption.getIvrRef(),
                      state: _vm.isRefValid,
                      trim: ""
                    },
                    on: { input: _vm.setOptionRef }
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { state: _vm.isRefValid } },
                    [_vm._v(" IVR reference should be 3 or more characters. ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("DestinationPicker", {
        attrs: {
          label: "Destination",
          "help-text": "Only the destinations you use will be saved.",
          destinations: _vm.destinations,
          destination: _vm.newIvrOption.getDestination()
        },
        on: {
          selectDestination: _vm.setOptionDestination,
          addDestination: _vm.onAddDestination
        }
      }),
      _c(
        "b-collapse",
        {
          attrs: { visible: _vm.newIvrOption.getDestination() ? true : false }
        },
        [
          _c("label", { staticClass: "mt-3" }, [_vm._v("Number")]),
          _c("p", [
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(
                "Choose the number pad option that will direct the caller to this destination."
              )
            ])
          ]),
          _c(
            "b-form-invalid-feedback",
            { attrs: { state: _vm.isDigitValid } },
            [
              _vm._v(
                " A number pad digit can only be used once on a dial plan. "
              )
            ]
          ),
          _c(
            "b-row",
            { attrs: { cols: "3" } },
            _vm._l(_vm.phonePadOptions, function(option) {
              return _c(
                "b-col",
                { key: option.digit, staticClass: "mx-auto my-2" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        size: "lg",
                        variant: _vm.digitsVariant,
                        pressed: _vm.newIvrOption.getDigit() === option.digit
                      },
                      on: {
                        click: function($event) {
                          return _vm.setDigit(option.digit)
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(option.digit)),
                      _c("br"),
                      _c("small", [_vm._v(_vm._s(option.label))])
                    ]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }