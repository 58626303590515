






































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import ModeStep from '@/components/wizard/ModeStep.vue'
import { DialPlan, DialPlanMode } from '@/models/dialplan/dialplan'
import BasicStep from '@/components/wizard/BasicStep.vue'
import IvrStep from '@/components/wizard/IvrStep.vue'
import TimeRoutingStep from '@/components/wizard/TimeRoutingStep.vue'
import ConfirmStep from '@/components/wizard/ConfirmStep.vue'
import { Destination } from '@/models/dialplan/destination'
import Steps, { StepConfig } from '@/components/steps/Steps.vue'

@Component({
  components: {
    BasicStep,
    IvrStep,
    ModeStep,
    TimeRoutingStep,
    ConfirmStep,
    Steps
  }
})
export default class Wizard extends Vue {
  @Prop({ required: true })
  dialPlan!: DialPlan

  get dialPlanMode () {
    return this.$store.state.newDialPlanMode
  }

  readonly steps: StepConfig[] = [
    {
      name: 'mode',
      title: 'Dial plan type',
      description: 'Choose which dial plan you want to create.',
      validator: () => {
        return this.isModeValid
      }
    },
    {
      name: 'basic',
      title: 'Basic information',
      description: 'Give your dial plan a name and choose a welcome message.',
      validator: () => {
        return this.isBasicValid
      }
    },
    {
      name: 'ivr',
      title: 'IVR options',
      description: 'Choose the number pad options to route callers to the right place.',
      validator: () => {
        return this.isIvrValid
      }
    },
    {
      name: 'time',
      title: 'Time Routing',
      description: 'Define your opening hours and where your callers are directed when you\'re closed.',
      pill: 'Advanced',
      disabled: () => { return this.dialPlanMode === DialPlanMode.Basic },
      validator: () => {
        return this.isTimeRoutingValid
      }
    },
    {
      name: 'confirm',
      title: 'Confirmation',
      description: 'Check over everything before completing.',
      validator: () => {
        return this.isModeValid &&
          this.isBasicValid &&
          this.isIvrValid &&
          this.isTimeRoutingValid
      }
    }
  ]

  onAddDestination (destination: Destination) {
    this.dialPlan.addDestination(destination.getDialPlanRef(), destination)
  }

  get isModeValid () {
    return this.dialPlanMode === DialPlanMode.Advanced || this.dialPlanMode === DialPlanMode.Basic
  }

  get isBasicValid (): boolean {
    return this.dialPlan.getName() !== '' && this.dialPlan.getWelcomePlaybackFilename() !== ''
  }

  get isIvrValid (): boolean {
    return this.dialPlan.getIvr().getIvrPlaybackFilename() !== '' &&
      this.dialPlan.getIvr().getOptions().length > 0
  }

  get isTimeRoutingValid (): boolean {
    if (this.dialPlanMode === DialPlanMode.Basic) {
      return true
    }

    return this.dialPlan.getTimeConfiguration().getClosedDestination() !== '' && this.dialPlan.getTimeConfiguration().getTimeZone() !== ''
  }

  get timeConfigOpenDays () {
    return this.dialPlan.getTimeConfiguration().getRecurringOpeningSchedule().filter(
      (schedule) => {
        return schedule.getStartTime() && schedule.getEndTime()
      }
    )
  }

  @Emit('complete')
  doComplete () {
    return this.dialPlan
  }
}
