import { IvrOption } from './ivr-option'
import Model, { apiToModelTransformer, modelToApiTransformer } from '@/models/base'
import { JsonData } from '@/helpers/api'

export class Ivr extends Model {
  protected fieldDefinition = {
    ivrPlaybackFilename: 'ivrPlaybackFilename'
  }

  private ivrPlaybackFilename = ''
  private options: IvrOption[] = []

  getIvrPlaybackFilename (): string {
    return this.ivrPlaybackFilename
  }

  setIvrPlaybackFilename (filename: string) {
    this.ivrPlaybackFilename = filename
    return this
  }

  getOptions (): IvrOption[] {
    return this.options
  }

  setOptions (options: IvrOption[]) {
    this.options = options
    return this
  }

  addOption (option: IvrOption) {
    this.options.push(option)

    return this
  }

  hasOption (option: IvrOption): boolean {
    return this.options.indexOf(option) > -1
  }

  removeOption (option: IvrOption) {
    const index = this.options.indexOf(option)

    if (index > -1) {
      this.options.splice(index, 1)
    }

    return this
  }

  fromApiTransformer (data: JsonData): Ivr {
    apiToModelTransformer(this, data, this.fieldDefinition)

    if (Array.isArray(data.options)) {
      for (const option of data.options) {
        const optionModel = new IvrOption()
          .fromApiTransformer(option)

        this.addOption(optionModel)
      }
    }

    return this
  }

  toApiTransformer (): JsonData {
    const data = modelToApiTransformer(this, this.fieldDefinition)
    data.options = []

    for (const option of this.options) {
      data.options.push(option.toApiTransformer())
    }

    return data
  }
}
