var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { attrs: { "align-v": "center" } },
    [
      _c(
        "b-col",
        [
          _c(
            "b-button",
            {
              attrs: {
                block: "",
                pressed: _vm.isModeBasic,
                variant: "outline-primary"
              },
              on: { click: _vm.doSelectBasic }
            },
            [
              _c("h5", [_vm._v("Basic")]),
              _c("p", [
                _vm._v(
                  "A basic dial plan, with up to 5 IVR options and no time routing."
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "b-col",
        [
          _c(
            "b-button",
            {
              attrs: {
                block: "",
                pressed: _vm.isModeAdvanced,
                variant: "outline-primary"
              },
              on: { click: _vm.doSelectAdvanced }
            },
            [
              _c("h5", [_vm._v("Advanced")]),
              _c("p", [
                _vm._v(
                  "More advanced dial plan, with up to 9 IVR options and basic time routing."
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }