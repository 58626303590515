var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-list-group-item",
    _vm._g(
      {
        staticClass: "d-flex justify-content-between align-items-center",
        attrs: { button: _vm.button }
      },
      _vm.$listeners
    ),
    [
      _c(
        "span",
        [
          _c("strong", [
            _vm._v(" " + _vm._s(_vm.destination.getDialPlanRef()) + " ")
          ]),
          _vm.destination.getMapItemKey()
            ? [
                _vm._v(" to "),
                _c("strong", [_vm._v(_vm._s(_vm.destination.getMapItemKey()))])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.destination.getPlaybackFilename()
        ? _c(
            "span",
            { class: _vm.selected ? "text-white" : "text-primary" },
            [
              _c("b-icon", { attrs: { icon: "soundwave" } }),
              _vm._v(" " + _vm._s(_vm.destination.getPlaybackFilename()) + " ")
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }