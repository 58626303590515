import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import DialPlans from '@/views/DialPlans.vue'
import Login from '@/views/Login.vue'
import { AuthUtil } from '@/utils/auth'
import Create from '@/views/Create.vue'
import Summary from '@/views/Summary.vue'
import Edit from '@/views/Edit.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'DialPlans',
    component: DialPlans,
    props: route => ({ query: route.query.installationId })
  },
  {
    path: '/create',
    name: 'Create',
    component: Create,
    props: route => ({ query: route.query.installationId })
  },
  {
    path: '/edit/:dialPlanId',
    name: 'Edit',
    component: Edit,
    props: true
  },
  {
    path: '/:id',
    name: 'Summary',
    props: { dialPlan: true },
    component: Summary
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(
  (to, from, next) => {
    if (to.meta && (to.meta.requiresAuth === false)) {
      if (AuthUtil.isAuthenticated) {
        next('/')

        return
      }

      next()

      return
    }

    if (AuthUtil.isAuthenticated === false) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })

      return
    }

    next()
  }
)

export default router
