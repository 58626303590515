




import { Component, Emit } from 'vue-property-decorator'
import Step from '@/components/steps/Step.vue'
import Summary from '@/components/Summary.vue'
import BaseStep from '@/components/wizard/BaseStep.vue'

@Component({
  components: {
    Step,
    Summary
  }
})
export default class ConfirmStep extends BaseStep {
  @Emit('confirm')
  doConfirm () {
    return this.dialPlan
  }
}
