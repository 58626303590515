var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "login" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                {
                  attrs: { id: "loginCol", "align-self": "center", cols: "5" }
                },
                [
                  _c(
                    "b-card",
                    { attrs: { title: "Login" } },
                    [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.onSubmit($event)
                            }
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "username-group",
                                description:
                                  "Enter the username you use to login to the Hub.",
                                label: "Username",
                                "label-for": "username",
                                state: _vm.formState
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "username",
                                  state: _vm.formState,
                                  trim: ""
                                },
                                model: {
                                  value: _vm.username,
                                  callback: function($$v) {
                                    _vm.username = $$v
                                  },
                                  expression: "username"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "password-group",
                                description:
                                  "Enter the password you use to login to the Hub.",
                                label: "Password",
                                "label-for": "password",
                                state: _vm.formState
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "password",
                                  type: "password",
                                  state: _vm.formState,
                                  trim: ""
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { state: _vm.formState } },
                            [
                              _vm._v(
                                " We couldn't verify your username or password. Try a different combination, or visit the Hub to reset your password. "
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "submit",
                                id: "loginButton",
                                variant: "success",
                                disabled: _vm.isLoading
                              }
                            },
                            [
                              _vm._v("Login "),
                              _vm.isLoading
                                ? _c("b-spinner", { attrs: { small: "" } })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }