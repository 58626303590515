




















import { Component, Vue } from 'vue-property-decorator'
import Summary from '@/components/Summary.vue'
import { DialPlan } from '@/models/dialplan/dialplan' // @ is an alias to /src
import { Api } from '@/helpers/api'
import { AuthUtil } from '@/utils/auth'
import Repository from '@/repos/base'
import Model from '@/models/base'

@Component({
  components: {
    Summary
  }
})
export default class SummaryView extends Vue {
  private dialPlan: DialPlan | Model | null = null
  private repo = new Repository(Api.DialPlanService, 'dialplans', DialPlan, 'data')

  async created () {
    if (!this.$store.state.dialPlan.id) {
      this.dialPlan = await this.repo
        .withParams({ installationId: AuthUtil.installationId })
        .find(this.$route.params.id)

      return
    }

    this.dialPlan = this.$store.state.dialPlan
  }
}
